<template>
  <div>
    <h1 style="margin: 0 0 2rem">Shopping Lists</h1>
    <TableSkeleton v-if="!loaded" />
    <div v-else-if="organizations.length > 0">
      <table class="table mb-4" v-for="(organization, organizationId) in organizations" :key="organizationId">
        <thead class="thead-light">
          <tr>
            <th colspan="2">{{ organization.name }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="resident in organization.residents" :key="resident.id">
            <td>{{ formatResidentName({ resident }) }}</td>
            <td class="text-right">{{ resident.itemCount }}</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <th colspan="2" class="text-right">
              {{ organization.itemCount }}
            </th>
          </tr>
        </tfoot>
      </table>
    </div>
    <div class="alert alert-warning" role="alert" v-else>
      No Shopping Lists founds
    </div>
  </div>
</template>

<script>
import { db } from '@/shared/firebase'
import TableSkeleton from '@/components/TableSkeleton'

export default {
  components: { TableSkeleton },
  data() {
    return {
      loaded: false,
      organizations: []
    }
  },
  async created() {
    const residents = await db
      .collection('residents')
      .orderBy('shoppingList')
      .get()
      .then(snapshot => {
        return snapshot.docs.map(doc => {
          const resident = { ...doc.data(), id: doc.id, itemCount: 0 }
          resident.itemCount = resident.shoppingList.items.map(item => item.amount).reduce((a, b) => a + b, 0)
          return resident
        })
      })
    const organizationIds = [...new Set(residents.map(r => r.organizations[0]))]
    const organizations = await Promise.all(
      organizationIds.map(organizationId => {
        return db
          .doc(`organizations/${organizationId}`)
          .get()
          .then(doc => {
            return { ...doc.data(), id: doc.id }
          })
      })
    )
    this.organizations = organizations.map(organization => {
      const data = {
        name: organization.name,
        id: organization.id,
        residents: residents.filter(r => r.organizations.includes(organization.id))
      }
      data.itemCount = data.residents.map(resident => resident.itemCount).reduce((a, b) => a + b, 0)
      return data
    })
    this.loaded = true
  }
}
</script>
